<template>
  <div>
    <aRow>
      <aCol :span="24">
        <aRow class="title">
          <aCol :span="24"> VALORES DOS SERVIÇOS </aCol>
        </aRow>

        <div v-if="contract.contracted_services.includes('hotel')">
          <aRow
            v-for="(hotel, i) in strToJson(contract.meta.hotel_rows)"
            :key="i"
            class="row"
          >
            <aCol :span="12"> HOSPEDAGEM #{{ hotel.id }} </aCol>
            <aCol :span="4"> </aCol>
            <aCol :span="8" class="a-right">
              {{
                contract.meta[`hotel_${hotel.id}_total_price`]
                  | formatPricePtBrComma
              }}</aCol
            >
          </aRow>
        </div>

        <div v-if="contract.contracted_services.includes('flight')">
          <aRow
            v-for="(flight, i) in strToJson(contract.meta.flight_sections_rows)"
            :key="i"
            class="row"
          >
            <aCol :span="12"> TRANSPORTE AÉREO #{{ flight.id }} </aCol>
            <aCol :span="4"> </aCol>
            <aCol :span="8" class="a-right">
              {{ flightTotalValue(flight) | formatPricePtBr }}
            </aCol>
          </aRow>
        </div>

        <div v-if="contract.contracted_services.includes('service')">
          <aRow
            v-for="(service, i) in strToJson(contract.meta.service_rows)"
            :key="i"
            class="row"
          >
            <aCol class="upper" :span="12">
              {{ contract.meta[`service_${service.id}_type`] }}
            </aCol>
            <aCol :span="4"> </aCol>
            <aCol :span="8" class="a-right">
              {{
                contract.meta[`service_${service.id}_total_price`]
                  | formatPricePtBrComma
              }}</aCol
            >
          </aRow>
        </div>

        <aRow class="row no-border">
          <aCol class="a-right" :span="24">
            <b>{{ contract.value | formatPricePtBr }}</b></aCol
          >
        </aRow>
      </aCol>

      <aCol :span="24">
        <aRow class="title">
          <aCol :span="24"> VALORES A PAGAR </aCol>
        </aRow>

        <div v-if="contract.contracted_services.includes('hotel')">
          <aRow
            v-for="(hotel, i) in strToJson(contract.meta.hotel_rows)"
            :key="i"
            class="row"
          >
            <aCol :span="10"> HOSPEDAGEM #{{ hotel.id }} </aCol>
            <aCol class="supplier" :span="6">
              <div class="dotted-phrase">
                {{
                  contract.meta[`contract_finances_hotel_${hotel.id}_supplier`]
                }}
              </div>
            </aCol>
            <aCol :span="8" class="a-right">
              {{
                contract.meta[`contract_finances_hotel_${hotel.id}_net_value`]
                  | formatPricePtBrComma
              }}</aCol
            >
          </aRow>
        </div>

        <div v-if="contract.contracted_services.includes('flight')">
          <aRow
            v-for="(flight, i) in strToJson(contract.meta.flight_sections_rows)"
            :key="i"
            class="row"
          >
            <aCol :span="10"> TRANSPORTE AÉREO #{{ flight.id }} </aCol>
            <aCol class="supplier" :span="6">
              <div class="dotted-phrase">
                {{
                  contract.meta[
                    `contract_finances_flight_${flight.id}_supplier`
                  ]
                }}
              </div>
            </aCol>
            <aCol :span="8" class="a-right">
              {{ flightTotalNetValue(flight) | formatPricePtBr }}
            </aCol>
          </aRow>
        </div>

        <div v-if="contract.contracted_services.includes('service')">
          <aRow
            v-for="(service, i) in strToJson(contract.meta.service_rows)"
            :key="i"
            class="row"
          >
            <aCol class="upper" :span="10">
              {{ contract.meta[`service_${service.id}_type`] }}
            </aCol>
            <aCol class="supplier" :span="6">
              <div class="dotted-phrase">
                {{
                  contract.meta[
                    `contract_finances_service_${service.id}_supplier`
                  ]
                }}
              </div>
            </aCol>
            <aCol :span="8" class="a-right">
              {{
                contract.meta[
                  `contract_finances_service_${service.id}_net_value`
                ] | formatPricePtBrComma
              }}</aCol
            >
          </aRow>
        </div>

        <aRow class="row no-border">
          <aCol class="a-right" :span="24">
            <b>{{
              contract.meta.contract_net_value | formatPricePtBr
            }}</b></aCol
          >
        </aRow>
      </aCol>

      <aCol :span="24">
        <aRow class="title">
          <aCol :span="24"> VALORES A RECEBER </aCol>
        </aRow>

        <aRow class="row no-border">
          <aCol :span="24"> --- </aCol>
        </aRow>
      </aCol>
    </aRow>
  </div>
</template>

<script>
import { parse } from "vue-currency-input";
import formatThings from "@/mixins/general/formatThings";

export default {
  name: "PrintSaleValuesColumnOneSection",
  props: {
    contract: Object,
  },
  mixins: [formatThings],
  data() {
    return {};
  },
  methods: {
    flightTotalNetValue(flight) {
      let totalValue = 0;

      flight.travellers.forEach((traveller) => {
        console.log(traveller, this.contract);

        totalValue += parse(
          this.contract.meta[
            `flight_${flight.id}_traveller_${traveller.id}_value`
          ]
        );
      });

      return totalValue;
    },
    flightTotalValue(flight) {
      let totalValue = 0;

      flight.travellers.forEach((traveller) => {
        console.log(traveller, this.contract);

        totalValue +=
          parse(
            this.contract.meta[
              `flight_${flight.id}_traveller_${traveller.id}_value`
            ]
          ) +
          parse(
            this.contract.meta[
              `flight_${flight.id}_traveller_${traveller.id}_taxes`
            ]
          ) +
          parse(
            this.contract.meta[
              `flight_${flight.id}_traveller_${traveller.id}_du_tax`
            ]
          ) +
          parse(
            this.contract.meta[
              `flight_${flight.id}_traveller_${traveller.id}_teb_agency`
            ]
          );
      });

      return totalValue;
    },
  },
};
</script>

<style lang="sass" scoped>
.row
  padding: 5px 0
  border-bottom: 1px solid #ddd
  font-size: 12px
.supplier
  font-size: 10px
  font-weight: 500
  color: #434a54
.no-border
  border: 0
.label
  font-size: 10px
  font-weight: 600
  color: #ccc
  margin-bottom: 3px
.title
  color: #434a54
  border-bottom: 1px solid #ddd
  font-size: 16px
  font-weight: 600
  margin-bottom: 10px
</style>
