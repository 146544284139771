<template>
  <div>
    <aRow
      class="product hotel"
      v-for="(hotel, i) in strToJson(contract.meta.hotel_rows)"
      :key="i"
    >
      <aCol :span="24">
        <aRow class="title">
          <aCol :span="24">
            <img
              class="relative"
              src="@/assets/images/dashboard/sales/print/hotel.png"
              width="16"
              alt="img"
              style="top: -2px; margin-right: 3px"
            />
            DADOS DA HOSPEDAGEM #{{ hotel.id }}
          </aCol>
        </aRow>

        <aRow class="row">
          <aCol :span="10">
            <div class="label">HOTEL</div>
            {{ contract.meta[`hotel_${hotel.id}_name`] }}
          </aCol>
          <aCol :span="6">
            <div class="label">REGIME ALIMENTAR</div>
            {{ contract.meta[`hotel_${hotel.id}_diet`] }}
          </aCol>
          <aCol :span="4">
            <div class="label">CHECK-IN</div>
            {{ contract.meta[`hotel_${hotel.id}_checkin`] | formatMultiDates }}
          </aCol>
          <aCol :span="4">
            <div class="label">CHECK-OUT</div>
            {{ contract.meta[`hotel_${hotel.id}_checkout`] | formatMultiDates }}
          </aCol>
        </aRow>

        <aRow v-for="(room, i) in hotel.accommodation" :key="i" class="row">
          <aCol :span="18">
            <div class="label">ACOMODAÇÃO</div>
            {{ contract.meta[`hotel_${hotel.id}_accommodation_${room.id}`] }}
          </aCol>
          <aCol :span="6">
            <div class="label">TIPO</div>
            {{
              contract.meta[`hotel_${hotel.id}_accommodation_${room.id}_type`]
            }}
          </aCol>
        </aRow>
      </aCol>
    </aRow>
  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings";

export default {
  name: "PrintSaleHotelsSection",
  props: {
    contract: Object,
  },
  mixins: [formatThings],
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="sass" scoped>
.row
  padding: 5px 0
  border-bottom: 1px solid #eee
  font-size: 13px
.label
  font-size: 10px
  font-weight: 600
  color: #ccc
  margin-bottom: 3px
.title
  margin-top: 10px
  color: #434a54
  border-bottom: 1px solid #ddd
  font-size: 16px
  font-weight: 600
  margin-bottom: 10px
</style>
