<template>
  <section class="bg pd-20" style="margin: -24px">
    <div id="document" class="doc-size">
      <aRow>
        <aCol class="haya-title a-center" :span="24">
          <img
            class="c-pointer"
            src="@/assets/images/dashboard/sales/print/haya-icon.png"
            width="14"
          />
          Haya Omnichannel
        </aCol>
      </aRow>

      <aRow type="flex" justify="space-between">
        <aCol>
          <img :src="contract.company_branch.logo" width="120" />
        </aCol>

        <aCol class="a-right">
          <div class="locator">
            LOCALIZADOR <b>#{{ sale.id }}</b>
          </div>
          <div class="meta">
            <span class="user">
              <a-icon class="ico" type="user" /> {{ contract.user.first_name }}
              {{ contract.user.last_name }}
            </span>
            <span class="date">
              <img
                class="relative"
                src="@/assets/images/dashboard/sales/print/calendar.png"
                width="12"
                style="top: -2px; margin-right: 3px"
              />
              {{ contract.created | formatDateTime }}
            </span>
            <span class="contract">
              <a-icon type="file-text" /> Contrato: {{ contract.id }}
            </span>
            <span class="status">
              <img
                class="relative"
                src="@/assets/images/dashboard/sales/print/price-tag.png"
                width="12"
                style="top: -2px; margin-right: 3px"
              />
              {{ sale.status }}</span
            >
          </div>
          <div class="services">
            <a-tag
              class="tag"
              v-for="(a, i) in formatContractedServices(
                contract.contracted_services
              )"
              :key="i"
              >{{ a }}</a-tag
            >
          </div>
        </aCol>
      </aRow>

      <div class="customer section">
        <aRow class="title">
          <aCol :span="12">
            <img
              class="relative"
              src="@/assets/images/dashboard/sales/print/contractor.png"
              width="16"
              style="top: -2px; margin-right: 3px"
            />
            CONTRATANTE
          </aCol>
          <aCol :span="12">
            <img
              class="relative"
              src="@/assets/images/dashboard/sales/print/pig.png"
              width="18"
              style="top: -2px; margin-right: 3px"
            />
            VALOR
          </aCol>
        </aRow>

        <aRow>
          <aCol :span="12">
            <div class="name upper">
              {{ contract.customer.id }} -
              <span v-if="contract.customer.person_type === 'Pessoa Física'">
                {{ contract.customer.first_name }}
                {{ contract.customer.last_name }}
              </span>
              <span v-if="contract.customer.person_type === 'Pessoa Jurídica'">
                {{ contract.customer.trading_name }}
              </span>
            </div>
            <div class="phone">{{ contract.customer.mobile_phone }}</div>
            <div class="mail">{{ contract.customer.email }}</div>
          </aCol>
          <aCol :span="12" class="value">
            {{ contract.value | formatPricePtBr }}
          </aCol>
        </aRow>
      </div>

      <div class="passengers section">
        <aRow class="title">
          <aCol :span="24">
            <img
              class="relative"
              src="@/assets/images/dashboard/sales/print/passenger.png"
              width="16"
              style="top: -2px; margin-right: 3px"
            />
            PASSAGEIROS
          </aCol>
        </aRow>

        <aRow class="subtitle">
          <aCol :span="8"> NOME </aCol>
          <aCol :span="8"> SOBRENOME </aCol>
          <aCol :span="8"> NASCIMENTO </aCol>
        </aRow>

        <aRow
          v-for="(traveller, i) in strToJson(contract.meta.travellers_rows)"
          :key="i"
          class="row"
        >
          <aCol class="upper" :span="8">
            <span class="ico">
              <a-icon type="user" />
              <font class="num">{{ traveller.id }} </font>
            </span>
            {{ contract.meta[`traveller_${traveller.id}_first_name`] }}
          </aCol>
          <aCol class="upper" :span="8">
            {{ contract.meta[`traveller_${traveller.id}_last_name`] }}
          </aCol>
          <aCol :span="8">
            {{
              contract.meta[`traveller_${traveller.id}_birthday`]
                | formatMultiDates
            }}
          </aCol>
        </aRow>
      </div>

      <PrintSaleHotelsSection
        v-if="contract.contracted_services.includes('hotel')"
        :contract="contract"
      />

      <PrintSaleFlightsSection
        v-if="contract.contracted_services.includes('flight')"
        :contract="contract"
      />

      <PrintSaleServicesSection
        v-if="contract.contracted_services.includes('service')"
        :contract="contract"
      />

      <aRow class="payments" :gutter="10">
        <aCol :span="12">
          <div class="gray-box">
            <PrintSaleValuesColumnOneSection
              v-if="contract.id"
              :contract="contract"
            />
          </div>
        </aCol>
        <aCol :span="12">
          <div class="gray-box">
            <PrintSaleValuesColumnTwoSection
              v-if="contract.id"
              :contract="contract"
            />
          </div>
        </aCol>
      </aRow>

      <aRow>
        <aCol class="print-by a-right" :span="24">
          Impresso por:
          <font class="name"
            >{{ $store.state.userData.first_name }}
            {{ $store.state.userData.last_name }},
            {{ new Date() | formatDateTimeObj }}
          </font>
        </aCol>
      </aRow>

      <!-- <json-viewer
        v-if="$store.state.userData.id == 1"
        class="mt-10 mb-20"
        :value="contract"
        :show-array-index="false"
        :expand-depth="1"
      ></json-viewer> -->
    </div>
  </section>
</template>

<script>
import html2pdf from "html2pdf.js";
import formatThings from "@/mixins/general/formatThings";
import PrintSaleHotelsSection from "./PrintSaleHotelsSection.vue";
import PrintSaleServicesSection from "./PrintSaleServicesSection.vue";
import PrintSaleFlightsSection from "./PrintSaleFlightsSection.vue";
import PrintSaleValuesColumnOneSection from "./PrintSaleValuesColumnOneSection.vue";
import PrintSaleValuesColumnTwoSection from "./PrintSaleValuesColumnTwoSection.vue";

export default {
  name: "PrintSaleSection",
  props: {
    sale: Object,
  },
  components: {
    PrintSaleHotelsSection,
    PrintSaleServicesSection,
    PrintSaleFlightsSection,
    PrintSaleValuesColumnOneSection,
    PrintSaleValuesColumnTwoSection,
  },
  mixins: [formatThings],
  data() {
    return {
      contract: {},
    };
  },
  created() {
    this.$on("onClickPrintSale", this.onClickPrintSale);
  },
  mounted() {
    this.getContractById();
  },
  methods: {
    onClickPrintSale() {
      const element = document.getElementById("document");
      const options = {
        filename: `Venda ${this.sale.id}.pdf`,
        margin: [0, -8, -8, -8],
        image: { type: "jpeg", quality: 1 },
        pagebreak: { mode: "avoid-all", after: ".avoidThisRow" },
        html2canvas: {
          scale: 2,
          proxy: "http://localhost:2686",
          useCORS: true,
          allowTaint: true,
          scrollX: 0,
          scrollY: 0,
        },
        jsPDF: {
          format: "a4",
          orientation: "portrait",
        },
      };

      html2pdf()
        .from(element)
        .set(options)
        .save()
        .then(() => (this.generatePDFLoading = false));
    },
    getContractById() {
      this.$http
        .get(`/contract-v2/details?id=${this.sale.contract_id}`)
        .then(({ data }) => {
          this.contract = data;
        });
    },
    formatContractedServices(arr) {
      let products = [];
      JSON.parse(arr).forEach((product) => {
        if (product === "hotel") products.push("Hospedagem");
        if (product === "flight") products.push("Aéreo");
        if (product === "service") products.push("Serviços");
      });
      return products;
    },
  },
};
</script>

<style lang="sass" scoped>
.doc-size
  max-width: 793px
  margin: 0 auto
  background: #FFF
  padding: 20px
  .payments
    margin-top: 20px
    .gray-box
      background: #f5f7fa
      padding: 5px
  .customer
    margin-top: 10px
    .mail
      text-transform: lowercase
      font-size: 12px
    .name, .value,  .phone
      font-size: 13px
  .passengers
    margin-top: 10px
    .row
      border-bottom: 1px solid #eee
      font-size: 12px
      padding: 3px 0
      .ico
        position: relative
        margin-right: 15px
        .num
          font-size: 10px
          font-weight: 600
          position: absolute
          top: 0
  .section
    .value
      font-weight: 600
      color: #434a54
    .name
      font-weight: 600
      text-transform: uppercase
      color: #434a54
    .title
      color: #434a54
      border-bottom: 1px solid #ddd
      font-size: 16px
      font-weight: 600
      margin-bottom: 10px
    .subtitle
      font-size: 10px
      font-weight: 600
      color: #ccc

  .print-by
    margin-top: 20px
    font-size: 10px
    font-weight: 500
    color: #939598
    .name
      color: #434a54
  .haya-title
    font-size: 10px
    color: #939598
    margin-bottom: 10px
  .locator
    font-size: 22px
    color: #000

  .services
    .tag
      margin: 0 0 0 2px
      background: #fff
      border-radius: 3px
      border-color: #b7b7b7
      font-size: 10px
      line-height: 1.3

  .meta
    font-size: 11px
    color: #58595b
    margin: 5px 0
    .user
      color: #000
    .ico
      margin-right: 3px
    span
      margin-left: 20px
</style>
