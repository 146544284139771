<template>
  <div>
    <aRow
      class="product flight"
      v-for="(flight, i) in strToJson(contract.meta.flight_sections_rows)"
      :key="i"
    >
      <aCol :span="24">
        <aRow class="title">
          <aCol :span="24">
            <img
              class="relative"
              src="@/assets/images/dashboard/sales/print/airplane-ticket.png"
              width="16"
              alt="img"
              style="top: -2px; margin-right: 3px"
            />
            TRANSPORTE AÉREO #{{ flight.id }}</aCol
          >
        </aRow>

        <aRow>
          <aCol :span="2">
            <div class="label">TRECHO</div>
          </aCol>

          <aCol :span="3">
            <div class="label">CIA</div>
          </aCol>

          <aCol :span="2">
            <div class="label">VÔO</div>
          </aCol>

          <!-- <aCol :span="2">
            <div class="label">PARADAS</div>
          </aCol> -->

          <aCol :span="2">
            <div class="label">ORIGEM</div>
          </aCol>

          <aCol :span="5">
            <div class="label">EMBARQUE</div>
          </aCol>

          <aCol :span="2">
            <div class="label">DESTINO</div>
          </aCol>

          <aCol :span="5">
            <div class="label">DESEMBARQUE</div>
          </aCol>

          <aCol :span="3">
            <div class="label">CLASSE</div>
          </aCol>
        </aRow>

        <aRow class="row" v-for="(section, i) in flight.sections" :key="i">
          <aCol :span="2">
            {{ section.id }}
          </aCol>
          <aCol :span="3">
            {{
              contract.meta[`flight_${flight.id}_section_${section.id}_airline`]
            }}
          </aCol>
          <aCol :span="2">
            {{
              contract.meta[`flight_${flight.id}_section_${section.id}_number`]
            }}
          </aCol>

          <aCol :span="2">
            {{
              contract.meta[`flight_${flight.id}_section_${section.id}_origin`]
            }}
          </aCol>

          <aCol :span="5">
            {{
              contract.meta[
                `flight_${flight.id}_section_${section.id}_departure_date`
              ] | formatMultiDates
            }}
            {{
              contract.meta[
                `flight_${flight.id}_section_${section.id}_departure_time`
              ]
            }}
          </aCol>

          <aCol :span="2">
            {{
              contract.meta[
                `flight_${flight.id}_section_${section.id}_destination`
              ]
            }}
          </aCol>

          <aCol :span="5">
            {{
              contract.meta[
                `flight_${flight.id}_section_${section.id}_arrival_date`
              ] | formatMultiDates
            }}
            {{
              contract.meta[
                `flight_${flight.id}_section_${section.id}_arrival_time`
              ]
            }}
          </aCol>

          <aCol :span="3">
            {{
              contract.meta[`flight_${flight.id}_section_${section.id}_class`]
            }}
          </aCol>
        </aRow>
      </aCol>
    </aRow>
  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings";

export default {
  name: "PrintSaleFlightsSection",
  props: {
    contract: Object,
  },
  mixins: [formatThings],
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="sass" scoped>
.row
  padding: 2px 0
  border-bottom: 1px solid #eee
  font-size: 12px
.label
  font-size: 10px
  font-weight: 600
  color: #ccc
.title
  margin-top: 10px
  color: #434a54
  border-bottom: 1px solid #ddd
  font-size: 16px
  font-weight: 600
  margin-bottom: 10px
</style>
