var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('aForm',{attrs:{"form":_vm.saleForm},on:{"submit":_vm.handleSubmit}},[_c('aRow',{attrs:{"gutter":20}},[_c('aCol',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled',attrs:{"for":""}},[_vm._v(" Empresa ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              `company_id`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]),expression:"[\n              `company_id`,\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'Obrigatório',\n                  },\n                ],\n              },\n            ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"Selecione","optionFilterProp":"txt","show-search":"","disabled":_vm.companiesList.length > 0 ? false : true},on:{"change":_vm.getCompanyBranchesOnChange}},_vm._l((_vm.companiesList),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id,"txt":item.trading_name}},[_vm._v("\n              "+_vm._s(item.id)+" - "+_vm._s(item.trading_name)+"\n            ")])}),1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled',attrs:{"for":""}},[_vm._v(" Filial ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              `company_branch_id`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]),expression:"[\n              `company_branch_id`,\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'Obrigatório',\n                  },\n                ],\n              },\n            ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"Selecione","optionFilterProp":"txt","show-search":"","disabled":_vm.companyBranchesList.length == 0}},_vm._l((_vm.companyBranchesList),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id,"txt":item.name}},[_vm._v("\n              "+_vm._s(item.id)+" - "+_vm._s(item.name)+"\n            ")])}),1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled',attrs:{"for":""}},[_vm._v(" Cliente ")]),_vm._v(" "),_c('a-auto-complete',{staticStyle:{"width":"100%"},attrs:{"data-source":_vm.customers.list.map(
                ({
                  first_name,
                  last_name,
                  person_type,
                  trading_name,
                  id,
                }) => ({
                  value: id,
                  text: `${id} -  ${
                    person_type == 'Pessoa Física'
                      ? first_name.toUpperCase() +
                        ' ' +
                        last_name.toUpperCase()
                      : trading_name.toUpperCase()
                  }`,
                })
              ),"placeholder":"Buscar cliente"},on:{"search":_vm.getCustomers,"select":_vm.onCustomerSelected},model:{value:(_vm.customers.filters.customerName),callback:function ($$v) {_vm.$set(_vm.customers.filters, "customerName", $$v)},expression:"customers.filters.customerName"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([`customer_id`]),expression:"[`customer_id`]"}],staticStyle:{"border-radius":"0"}})],1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled',attrs:{"for":""}},[_vm._v(" Usuário ")]),_vm._v(" "),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              `user_id`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]),expression:"[\n              `user_id`,\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'Obrigatório',\n                  },\n                ],\n              },\n            ]"}],staticClass:"travel-input",staticStyle:{"width":"100%"},attrs:{"placeholder":"Selecione","optionFilterProp":"txt","show-search":""}},_vm._l((_vm.userList),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id,"txt":item.first_name}},[_vm._v("\n              "+_vm._s(item.id)+" - "+_vm._s(item.first_name)+" "+_vm._s(item.last_name)+"\n            ")])}),1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled',attrs:{"for":""}},[_vm._v(" Data da venda ")]),_vm._v(" "),_c('a-date-picker',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"},{name:"decorator",rawName:"v-decorator",value:([
              `sale_date`,
              {
                rules: [
                  {
                    required: true,
                    message: 'Obrigatório',
                  },
                ],
              },
            ]),expression:"[\n              `sale_date`,\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: 'Obrigatório',\n                  },\n                ],\n              },\n            ]"}],staticClass:"travel-input f10",staticStyle:{"width":"100%"},attrs:{"placeholder":"Data da venda","format":"DD/MM/YYYY","showToday":false,"allowClear":true}},[_c('a-icon',{attrs:{"slot":"prefix","type":"field-svg"},slot:"prefix"})],1)],1)],1),_vm._v(" "),_c('aCol',{attrs:{"span":12}},[_c('a-form-item',{staticClass:"travel-input-outer"},[_c('label',{class:'filled',attrs:{"for":""}},[_vm._v(" Anti-fraude Manual ")]),_vm._v(" "),_c('aSelect',{staticClass:"travel-input",staticStyle:{"width":"84%"},attrs:{"placeholder":"Selecione","optionFilterProp":"txt","show-search":"","disabled":_vm.antiFraudList.length > 0 ? false : true},model:{value:(_vm.antiFraud),callback:function ($$v) {_vm.antiFraud=$$v},expression:"antiFraud"}},_vm._l((_vm.antiFraudList),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.label,"txt":item.label}},[_vm._v("\n              "+_vm._s(item.label)+"\n            ")])}),1),_vm._v(" "),(_vm.antiFraud !== _vm.antiFraudDB)?_c('a-button',{staticClass:"ml-10 f10 confirm-btn",attrs:{"type":"primary","size":"small","shape":"circle","icon":"check"},on:{"click":_vm.onClickUpdateManualAntiFraud}}):_vm._e()],1)],1)],1),_vm._v(" "),_c('aRow',{attrs:{"gutter":20}},[_c('aCol',{staticClass:"a-center",attrs:{"span":24,"offset":0}},[_c('aButton',{attrs:{"type":"primary","html-type":"submit","loading":_vm.sale.loading}},[_vm._v("\n          Salvar alterações\n        ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }