<template>
  <div>
    <aForm :form="saleForm" @submit="handleSubmit">
      <aRow :gutter="20">
        <aCol :span="12">
          <a-form-item class="travel-input-outer">
            <label for :class="'filled'"> Empresa </label>
            <a-select
              class="travel-input"
              placeholder="Selecione"
              optionFilterProp="txt"
              @change="getCompanyBranchesOnChange"
              v-decorator="[
                `company_id`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
              show-search
              style="width: 100%"
              :disabled="companiesList.length > 0 ? false : true"
            >
              <a-select-option
                v-for="(item, index) of companiesList"
                :key="index"
                :value="item.id"
                :txt="item.trading_name"
              >
                {{ item.id }} - {{ item.trading_name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </aCol>

        <aCol :span="12">
          <a-form-item class="travel-input-outer">
            <label for :class="'filled'"> Filial </label>

            <a-select
              class="travel-input"
              placeholder="Selecione"
              optionFilterProp="txt"
              show-search
              style="width: 100%"
              :disabled="companyBranchesList.length == 0"
              v-decorator="[
                `company_branch_id`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
            >
              <a-select-option
                v-for="(item, index) of companyBranchesList"
                :key="index"
                :value="item.id"
                :txt="item.name"
              >
                {{ item.id }} - {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </aCol>

        <aCol :span="12">
          <a-form-item class="travel-input-outer">
            <label for :class="'filled'"> Cliente </label>

            <a-auto-complete
              :data-source="
                customers.list.map(
                  ({
                    first_name,
                    last_name,
                    person_type,
                    trading_name,
                    id,
                  }) => ({
                    value: id,
                    text: `${id} -  ${
                      person_type == 'Pessoa Física'
                        ? first_name.toUpperCase() +
                          ' ' +
                          last_name.toUpperCase()
                        : trading_name.toUpperCase()
                    }`,
                  })
                )
              "
              style="width: 100%"
              placeholder="Buscar cliente"
              v-model="customers.filters.customerName"
              @search="getCustomers"
              @select="onCustomerSelected"
            >
              <a-input v-decorator="[`customer_id`]" style="border-radius: 0" />
            </a-auto-complete>
          </a-form-item>
        </aCol>

        <aCol :span="12">
          <a-form-item class="travel-input-outer">
            <label for :class="'filled'"> Usuário </label>

            <a-select
              class="travel-input"
              placeholder="Selecione"
              optionFilterProp="txt"
              v-decorator="[
                `user_id`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
              show-search
              style="width: 100%"
            >
              <a-select-option
                v-for="(item, index) of userList"
                :key="index"
                :value="item.id"
                :txt="item.first_name"
              >
                {{ item.id }} - {{ item.first_name }} {{ item.last_name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </aCol>

        <aCol :span="12">
          <a-form-item class="travel-input-outer">
            <label for :class="'filled'"> Data da venda </label>
            <a-date-picker
              class="travel-input f10"
              placeholder="Data da venda"
              format="DD/MM/YYYY"
              :showToday="false"
              :allowClear="true"
              v-mask="'##/##/####'"
              v-decorator="[
                `sale_date`,
                {
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
              style="width: 100%"
            >
              <a-icon slot="prefix" type="field-svg" />
            </a-date-picker>
          </a-form-item>
        </aCol>

        <aCol :span="12">
          <a-form-item class="travel-input-outer">
            <label for :class="'filled'"> Anti-fraude Manual </label>
            <aSelect
              class="travel-input"
              placeholder="Selecione"
              optionFilterProp="txt"
              v-model="antiFraud"
              show-search
              style="width: 84%"
              :disabled="antiFraudList.length > 0 ? false : true"
            >
              <a-select-option
                v-for="(item, index) of antiFraudList"
                :key="index"
                :value="item.label"
                :txt="item.label"
              >
                {{ item.label }}
              </a-select-option>
            </aSelect>
            <a-button
              class="ml-10 f10 confirm-btn"
              v-if="antiFraud !== antiFraudDB"
              @click="onClickUpdateManualAntiFraud"
              type="primary"
              size="small"
              shape="circle"
              icon="check"
            />
          </a-form-item>
        </aCol>
      </aRow>

      <aRow :gutter="20">
        <aCol class="a-center" :span="24" :offset="0">
          <aButton type="primary" html-type="submit" :loading="sale.loading">
            Salvar alterações
          </aButton>
        </aCol>
      </aRow>
    </aForm>
  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings";
import customerMixins from "@/components/customers/mixins/customerMixins";
import saleMixins from "@/mixins/sales/saleMixins.js";

export default {
  props: {
    id: String,
    saleCustomer: Object,
  },
  mixins: [formatThings, customerMixins, saleMixins],
  data() {
    return {
      saleForm: this.$form.createForm(this),
      companiesList: [],
      companyBranchesList: [],
      userList: [],
      antiFraudList: [],
      antiFraud: undefined,
      antiFraudDB: undefined,
      theSale: {},
      customerName: "",
    };
  },
  mounted() {
    this.$http.get(`/company/list?page=1&per_page=100`).then(({ data }) => {
      this.companiesList = data.data;
    });

    this.$http.get(`/user/list?page=1&per_page=200`).then(({ data }) => {
      this.userList = data.data;
    });

    this.$http
      .get(
        `/category-entry/list?page=1&per_page=500&category_id=37&order-by=name&order=ascend`
      )
      .then(({ data }) => {
        this.antiFraudList = data.data.map(({ name }) => {
          return {
            label: `${name}`,
            value: name,
          };
        });
      });

    this.$http
      .get(
        `/customer/list?page=1&per_page=200&customer_name=${this.saleCustomer.id}`
      )
      .then(({ data }) => {
        this.customers.list = data.data;
      });

    this.$http
      .get(
        `/company-branch/list?page=1&per_page=100&status=Ativo&show_in_contract=1`
      )
      .then(({ data }) => {
        this.companyBranchesList = data.data;
      });

    this.getSaleById(this.id).then(({ data }) => {
      this.saleForm.setFieldsValue(data.raw);
      this.theSale = data;
      this.antiFraud = data.contract.meta.manual_anti_fraud ?? undefined;
      this.antiFraudDB = data.contract.meta.manual_anti_fraud ?? undefined;
    });

    this.customers.filters.customerName =
      this.saleCustomer.person_type == "Pessoa Física"
        ? `${this.saleCustomer.id} - ${this.saleCustomer.first_name} ${this.saleCustomer.last_name}`
        : `${this.saleCustomer.id} - ${this.saleCustomer.trading_name}`;
  },
  methods: {
    onCustomerSelected(customerId) {
      this.saleForm.setFieldsValue({
        [`customer_id`]: customerId,
      });
    },
    onClickUpdateManualAntiFraud() {
      this.$http
        .post("/contract/update-multi-meta", {
          id: this.theSale.raw.contract_id,
          manual_anti_fraud: this.antiFraud,
          modified_by: {
            name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
            id: this.$store.state.userData.id,
          },
        })
        .then(() => {
          this.$message.success("Anti fraude manual atualizado com sucesso!");

          if (this.antiFraud !== this.antiFraudDB) {
            let fraudTxt = "status";
            if (this.antiFraud === "Concluída") fraudTxt = "concluded";

            this.$http.post("/log/create", {
              user_id: this.$store.state.userData.id,
              module_id: this.theSale.raw.contract_id,
              module: "contract",
              action: `contract-manual-anti-fraud-${fraudTxt}`,
              description: `<font class="user">${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}</font> alterou a análise anti-fraude para <b>${this.antiFraud}</b>.`,
            });

            this.$http
              .post("/notification/create", {
                user_id: this.theSale.raw.user_id,
                created_by: this.$store.state.userData.id,
                title: "Análise Anti-fraude manual",
                action: `/contracts/list?id=${this.theSale.raw.contract_id}`,
                content: `<font class="user">${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}</font> alterou a análise anti-fraude para <b>${this.antiFraud}</b>.`,
              })
              .then(({ data }) => {
                this.$http.post("/note/create", {
                  user_id: this.$store.state.userData.id,
                  module_id: this.theSale.raw.id,
                  title: "Análise Anti-fraude manual",
                  module: "sale",
                  notification_id: data.id,
                  reason: `Análise de Fraude`,
                  content: `Análise anti-fraude <b>${this.antiFraud}</b>.`,
                });
              });

            this.antiFraudDB = this.antiFraud;
          }
        });
    },

    getCompanyBranchesOnChange(id) {
      this.saleForm.setFieldsValue({
        [`company_branch_id`]: undefined,
      });

      this.companyBranchesList = [];
      this.getCompanyBranches(id);
    },
    getCompanyBranches(companyId) {
      this.$http
        .get(
          `/company-branch/list?page=1&per_page=100&status=Ativo&company_id=${companyId}&show_in_contract=1`
        )
        .then(({ data }) => {
          this.companyBranchesList = data.data;
        });
    },

    handleSubmit(e) {
      e.preventDefault();
      this.$store.state.createContract = false;
      this.saleForm.validateFields((err, values) => {
        this.theSale.raw.company_branch_id = values.company_branch_id;
        this.theSale.raw.company_id = values.company_id;
        this.theSale.raw.customer_id = values.customer_id;
        this.theSale.raw.sale_date = values.sale_date;
        this.theSale.raw.user_id = values.user_id;
        this.theSale.raw.action = "update-sale-basic-data";
        this.theSale.raw.description = `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name} atualizou os dados básicos da venda ID ${this.theSale.raw.id}.`;
        this.theSale.raw.modified_by = {
          name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
          id: this.$store.state.userData.id,
        };

        if (!err) {
          this.sale.loading = true;
          this.$http
            .post("/sale/update", this.theSale.raw)
            .then(({ data }) => {
              this.$message.success(data.message);
              this.sale.loading = false;
            })
            .catch(({ response }) => {
              this.$message.error(response.data.message);
              this.sale.loading = false;
            });
        }
      });
    },
  },
};
</script>
